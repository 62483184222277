var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[(_vm.certificate.liveEvent.title)?_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("Certificates: "+_vm._s(_vm.certificate.liveEvent.title))])]):_vm._e(),_c('div',{staticClass:"card-body"},[(_vm.memberCertificates.length > 0)?_c('div',{staticClass:"row"},_vm._l((_vm.memberCertificates),function(certificate,index){return _c('div',{key:index,staticClass:"col-md-4 col-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("Certificate - "+_vm._s(index + 1))])]),_c('div',{staticClass:"card-body"},[_c('img',{staticClass:"img-thumbnail",attrs:{"src":_vm.base_s3_url_certificate +
                      '/' +
                      certificate.file_name +
                      '.png'}})]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[(certificate.live_event_member)?_c('div',{staticClass:"col-md-12"},[_c('p',[_vm._v(" "+_vm._s(certificate.live_event_member.fname + " " + certificate.live_event_member.lname)+" ")]),_c('p',[_vm._v(_vm._s(certificate.live_event_member.email))])]):(certificate.member)?_c('div',{staticClass:"col-md-12"},[_c('p',[_vm._v(" "+_vm._s(certificate.member.fname + " " + certificate.member.lname)+" ")]),_c('p',[_vm._v(_vm._s(certificate.member.email))])]):_c('div',{staticClass:"col-md-12"},[_c('p',[_vm._v("Live Event Member or Member Not Found")]),_c('p',[_vm._v(_vm._s(certificate.mobile_number))])]),_c('div',{staticClass:"col-md-12"},[_c('b-button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.regenerateCertificate(certificate.id)}}},[_vm._v("Regenerate")]),_c('a',{staticStyle:{"padding":"10px"},attrs:{"download":certificate.file_name + '.png',"href":_vm.base_s3_url_certificate +
                          '/' +
                          certificate.file_name +
                          '.png'}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Download")])])],1)])])])])}),0):_vm._e(),(_vm.memberCertificates.length == 0)?[_c('div',{staticClass:"no_content my-4"},[_c('div',{staticStyle:{"display":"grid","justify-content":"center"}},[_c('img',{attrs:{"src":require("../../../../public/noData.svg"),"alt":""}}),_c('span',{staticClass:"no_content_text text-center"},[_vm._v("No Content Available")])])])]:_vm._e()],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }