<template>
  <layout>
    <div class="row">
      <div class="col-md-12">
        <!-- {{ certificate.liveEvent.title }} -->
        <div class="card">
          <div class="card-header" v-if="certificate.liveEvent.title">
            <h3>Certificates: {{ certificate.liveEvent.title }}</h3>
          </div>
          <div class="card-body">
            <div class="row" v-if="memberCertificates.length > 0">
              <div
                class="col-md-4 col-4"
                v-for="(certificate, index) in memberCertificates"
                :key="index"
              >
                <div class="card">
                  <div class="card-header">
                    <h3>Certificate - {{ index + 1 }}</h3>
                  </div>
                  <div class="card-body">
                    <img
                      class="img-thumbnail"
                      :src="
                        base_s3_url_certificate +
                        '/' +
                        certificate.file_name +
                        '.png'
                      "
                    />
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div
                        class="col-md-12"
                        v-if="certificate.live_event_member"
                      >
                        <p>
                          {{
                            certificate.live_event_member.fname +
                            " " +
                            certificate.live_event_member.lname
                          }}
                        </p>
                        <p>{{ certificate.live_event_member.email }}</p>
                      </div>
                      <div class="col-md-12" v-else-if="certificate.member">
                        <p>
                          {{
                            certificate.member.fname +
                            " " +
                            certificate.member.lname
                          }}
                        </p>
                        <p>{{ certificate.member.email }}</p>
                      </div>
                      <div class="col-md-12" v-else>
                        <p>Live Event Member or Member Not Found</p>
                        <p>{{ certificate.mobile_number }}</p>
                      </div>
                      <div class="col-md-12">
                        <!-- <a href="{{ route('regenerate.certificate',$certificate->id) }}"><button class="btn btn-primary">Regenerate</button></a> -->
                        <b-button
                          class="btn btn-primary"
                          @click="regenerateCertificate(certificate.id)"
                          >Regenerate</b-button
                        >
                        <a
                          :download="certificate.file_name + '.png'"
                          :href="
                            base_s3_url_certificate +
                            '/' +
                            certificate.file_name +
                            '.png'
                          "
                          style="padding: 10px"
                          ><button class="btn btn-primary">Download</button></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="memberCertificates.length == 0">
              <div class="no_content my-4">
                <div style="display: grid; justify-content: center">
                  <img src="../../../../public/noData.svg" alt="" />
                  <span class="no_content_text text-center"
                    >No Content Available</span
                  >
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import liveEvent from "../../../mixins/ModuleJs/live-event";
export default {
  mixins: [MixinRequest, liveEvent],
  name: "show-certificates",
  data() {
    return {
      certificate: [],
    };
  },
  components: {
    Layout,
    //   PageHeader
  },
  method: {
    mounted() {
      //   if(this.$route.name == 'show-certificates'){
      this.getCertificate(this.$route.params.id);
      console.log(this.$route.params.id);
      //   }
    },
  },
};
</script>
